@import url(https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
}

body {
  background-color: #f5f6fa;
}

.app {
  display: flex;
  justify-content: space-evenly;
  padding: 20px;
}

.app__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.app__stats {
  display: flex;
  justify-content: space-between;
}

.app__left {
  flex: 0.9 1;
}

.app__right {
  display: flex;
  flex-direction: column;
}

.app__graphTitle {
  margin-top: 20px;
  margin-bottom: 20px;
}

.app__right .MuiCardContent-root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.app__graph {
  flex-grow: 1;
}

@media (max-width: 990px) {
  .app {
    flex-direction: column;
  }
}

.infoBox {
  flex: 1 1;
  cursor: pointer;
}

.infoBox:not(:last-child) {
  margin-right: 15px;
}

.infoBox__cases {
  color: #cc1034;
  font-weight: 600;
  font-size: 1.75rem;
  margin-bottom: 0.5rem;
}

.infoBox--selected {
  border-top: 10px solid greenyellow;
}

.infoBox--red {
  border-color: red;
}

.infoBox__cases--green {
  color: lightgreen;
}

.infoBox--grey {
  border-color: grey;
}

.infoBox__cases--grey {
  color: grey;
}

.infoBox__total {
  color: #6c757d;
  font-weight: 700 !important;
  font-size: 0.8rem !important;
  margin-top: 15px !important;
}

.map {
  height: 500px;
  background-color: white;
  padding: 1rem;
  border-radius: 20px;
  margin-top: 16px;
  box-shadow: 0 0 8px -4px rgba(0, 0, 0, 0.5);
}

.info-flag img {
  width: 100px;
  border-radius: 5px;
}

.info-flag {
  height: 80px;
  width: 100%;
  background-size: cover;
  border-radius: 8px;
}

.info-confirmed,
.info-deaths,
.info-recovered {
  font-size: 16px;
  margin-top: 5px;
}

.info-name {
  font-size: 20px;
  font-weight: bold;
  color: #555;
}

.table {
  margin-top: 20px;
  overflow: scroll;
  height: 400px;
  color: #6a5d5d;
  background-color: #fff;
}

.table tr {
  display: flex;
  justify-content: space-between;
}

.table td {
  padding: 0.5rem;
}
.table tr:nth-of-type(odd) {
  background-color: #f3f2f8;
}

