* {
  margin: 0;
}

body {
  background-color: #f5f6fa;
}

.app {
  display: flex;
  justify-content: space-evenly;
  padding: 20px;
}

.app__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.app__stats {
  display: flex;
  justify-content: space-between;
}

.app__left {
  flex: 0.9;
}

.app__right {
  display: flex;
  flex-direction: column;
}

.app__graphTitle {
  margin-top: 20px;
  margin-bottom: 20px;
}

.app__right .MuiCardContent-root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.app__graph {
  flex-grow: 1;
}

@media (max-width: 990px) {
  .app {
    flex-direction: column;
  }
}
