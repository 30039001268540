@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

.infoBox {
  flex: 1;
  cursor: pointer;
}

.infoBox:not(:last-child) {
  margin-right: 15px;
}

.infoBox__cases {
  color: #cc1034;
  font-weight: 600;
  font-size: 1.75rem;
  margin-bottom: 0.5rem;
}

.infoBox--selected {
  border-top: 10px solid greenyellow;
}

.infoBox--red {
  border-color: red;
}

.infoBox__cases--green {
  color: lightgreen;
}

.infoBox--grey {
  border-color: grey;
}

.infoBox__cases--grey {
  color: grey;
}

.infoBox__total {
  color: #6c757d;
  font-weight: 700 !important;
  font-size: 0.8rem !important;
  margin-top: 15px !important;
}
